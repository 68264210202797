/* eslint-disable indent */
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  getEsgianTheme,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getFilterModal,
  getIsLookupLoading,
  getSearchText,
  getThemeMode,
  getViewTab,
} from "../../store/selector/common";
import {
  getVesselContractChart,
  getVesselContractFilterOptions,
  getVesselContractList,
  getVesselContractSelectedFilter,
  getVesselIsLoading,
} from "../../store/selector/contract";
import {
  setFilterModal,
  setSelectedCableLayId,
  setSelectedFloatingTechnologyId,
  setSelectedInstallationId,
  setSelectedProjectId,
  setSelectedTurbineModelId,
} from "../../store/slice/common";
import {
  fetchVesselContractChart,
  fetchVesselContractList,
  setVesselContractSelectedFilter,
} from "../../store/slice/contract";
import {
  DataTableMetadata,
  FloatingInstallation,
  GenericType,
  Maintenance,
  THEME,
  ThemeModeEnum,
  UnifiedVesselContract,
  VesselContractChartTabEnum,
  VesselContractTabEnum,
  ViewTabEnum,
} from "../../types";
import { ContractChart } from "../ContractChart";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";
import { FilterModal } from "../Modal/FilterModal";
import { VesselContractList } from "../VesselContractList";
import { VesselTimeline } from "../VesselTimeline";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    firstColumn: {
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  };
};

const legends: GenericType = {
  Foundation: "rgb(145, 51, 200)",
  OMS: "rgb(214, 236, 11)",
  Turbine: "rgb(82, 202, 238)",
  "Vessel under construction": "rgb(0, 139, 106)",
  "Cable lay": "rgb(255, 105, 180)",
  "Foundation estimated": `repeating-linear-gradient(
    45deg,
    white 0 5px,
    rgb(145, 51, 200) 5px 15px)
  `,
  "OMS estimated": `repeating-linear-gradient(
    45deg,
    white 0 5px,
    rgb(214, 236, 11) 5px 15px)
  `,
  "Turbine estimated": `repeating-linear-gradient(
    45deg,
    white 0 5px,
    rgb(82, 202, 238) 5px 15px)
  `,
  "Vessel under construction estimated": `repeating-linear-gradient(
    45deg,
    white 0 5px,
    rgb(0, 139, 106) 5px 15px)
  `,
  "Cable lay estimated": `repeating-linear-gradient(
    45deg,
    white 0 5px,
    rgb(255, 105, 180) 5px 15px)
  `,
};

export const VesselContract = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const viewTab = useSelector(getViewTab);
  const countries = useSelector(getCountries);
  const searchText = useSelector(getSearchText);
  const isFilterModalOpen = useSelector(getFilterModal);
  const vesselList = useSelector(getVesselContractList);
  const options = useSelector(getVesselContractFilterOptions);
  const selectedFilter = useSelector(getVesselContractSelectedFilter);
  const vesselContractChart = useSelector(getVesselContractChart);
  const isLoading = useSelector(getVesselIsLoading);
  const isLookupLoading = useSelector(getIsLookupLoading);
  const [tab, setTab] = useState<VesselContractTabEnum>(
    VesselContractTabEnum.AllVessels,
  );
  const [chartTab, setChartTab] = useState(
    VesselContractChartTabEnum.ContractOverview,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setVesselContractSelectedFilter({ tab, filterValues: {} }));
    setTab(event.target.value as VesselContractTabEnum);
  };

  const handleChartTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartTab(event.target.value as VesselContractChartTabEnum);
  };

  const data = useMemo(() => {
    let list = vesselList?.[tab] as UnifiedVesselContract[];
    const filter = selectedFilter?.[tab] || [];
    if (!list || !list.length) return list;
    list = filterDataBasedOnDynamicKeys(
      list as GenericType[],
      filter,
    ) as UnifiedVesselContract[];
    if (searchText?.length && list?.length) {
      list = list?.filter((item: GenericType) =>
        isMatch(item, [searchText]),
      ) as UnifiedVesselContract[];
    }
    return list || [];
  }, [vesselList, tab, searchText, selectedFilter]);

  const columns = useMemo(() => {
    return {
      "all-vessels": [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        {
          name: "vesselName",
          label: "Vessel name",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "vesselOwner", label: "Vessel owner" },
        { name: "equipmentType", label: "Contracted equipment type" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "startDate", label: "Start date" },
        { name: "endDate", label: "End date" },
        { name: "contractWorkType", label: "Contract work type" },
        {
          name: "turbineModel",
          label: "Turbine model",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "foundationType", label: "Foundation type" },
        { name: "cableType", label: "Cable type" },
        { name: "contractStatus", label: "Contract status" },
      ],
      installation: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        {
          name: "vesselName",
          label: "Vessel name",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "vesselOwner", label: "Vessel owner" },
        { name: "equipmentType", label: "Contracted equipment type" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "startDate", label: "Start date" },
        { name: "endDate", label: "End date" },
        {
          name: "turbineModel",
          label: "Turbine model",
          options: {
            customBodyRender: (value: string) => {
              return (
                <Box sx={{ color: "#0E9EFF", cursor: "pointer" }}>{value}</Box>
              );
            },
          },
        },
        { name: "foundationType", label: "Foundation type" },
        { name: "contractStatus", label: "Contract status" },
      ],
      "cable-lay": [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        {
          name: "vesselName",
          label: "Vessel name",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedInstallationId(
                        data?.[tableMeta?.rowIndex]?.vesselImo,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        {
          name: "vesselOwner",
          label: "Vessel owner",
        },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedProjectId(
                        data?.[tableMeta?.rowIndex]?.windFarmId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "startDate", label: "Start date" },
        { name: "endDate", label: "End date" },
        { name: "equipmentType", label: "Cable type" },
        { name: "contractStatus", label: "Contract status" },
      ],
      "floating-installation": [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        {
          name: "vesselName",
          label: "Vessel name",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedInstallationId(
                        data?.[tableMeta?.rowIndex]?.vesselImo,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        { name: "vesselOwner", label: "Vessel owner" },
        { name: "equipmentType", label: "Contracted equipment type" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedProjectId(
                        data?.[tableMeta?.rowIndex]?.windFarmId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "startDate", label: "Start date" },
        { name: "endDate", label: "End date" },
        {
          name: "floatingTechnology",
          label: "Floating technology",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedFloatingTechnologyId(
                        (data?.[tableMeta?.rowIndex] as FloatingInstallation)
                          .floatingTechnologyId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
      ],
      maintenance: [
        {
          name: "contractAwardDate",
          label: "Contract award date",
          options: {
            customBodyRender: (value: string) => {
              return <Box sx={classes.firstColumn}>{value}</Box>;
            },
          },
        },
        {
          name: "vesselName",
          label: "Vessel name",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedInstallationId(
                        data?.[tableMeta?.rowIndex]?.vesselImo,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        {
          name: "vesselOwner",
          label: "Vessel owner",
        },
        { name: "equipmentType", label: "Contracted equipment type" },
        {
          name: "windFarm",
          label: "Wind farm",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedProjectId(
                        data?.[tableMeta?.rowIndex]?.windFarmId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        { name: "country", label: "Country" },
        { name: "startDate", label: "Start date" },
        { name: "endDate", label: "End date" },
        {
          name: "turbineModel",
          label: "Turbine model",
          options: {
            customBodyRender: (value: string, tableMeta: DataTableMetadata) => {
              return (
                <Box
                  sx={{ color: "#0E9EFF", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setSelectedTurbineModelId(
                        (data?.[tableMeta?.rowIndex] as Maintenance)
                          .turbineModelId,
                      ),
                    )
                  }
                >
                  {value}
                </Box>
              );
            },
          },
        },
        { name: "foundationType", label: "Foundation type" },
      ],
    }[tab];
  }, [classes.firstColumn, data, dispatch, tab]);

  const leadTimeData = useMemo(() => {
    if (tab === VesselContractTabEnum.Installation) {
      return vesselContractChart?.installationLeadTime;
    }
    if (tab === VesselContractTabEnum.CableLay) {
      return vesselContractChart?.cableLayLeadTime;
    }
  }, [tab, vesselContractChart]);

  useEffect(() => {
    if (!isLookupLoading && countries.length) {
      dispatch(fetchVesselContractList());
      dispatch(fetchVesselContractChart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupLoading, countries]);

  useEffect(() => {
    if (
      chartTab === VesselContractChartTabEnum.LeadTime &&
      tab !== VesselContractTabEnum.Installation &&
      tab !== VesselContractTabEnum.CableLay
    ) {
      setChartTab(VesselContractChartTabEnum.ContractOverview);
    }
  }, [chartTab, tab]);

  const vesselDrawerMethod = (imo: number) => {
    if (tab === VesselContractTabEnum.CableLay) {
      return dispatch(setSelectedCableLayId(imo));
    }

    return dispatch(setSelectedInstallationId(imo));
  };

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <FormLabel id="view-radio-buttons">View by</FormLabel>
            </Grid>
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={tab}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={VesselContractTabEnum.AllVessels}
                  control={<Radio />}
                  label="All Vessels"
                />
                <FormControlLabel
                  value={VesselContractTabEnum.CableLay}
                  control={<Radio />}
                  label="Cable lay"
                />
                {viewTab === ViewTabEnum.List ? (
                  <FormControlLabel
                    value={VesselContractTabEnum.FloatingInstallation}
                    control={<Radio />}
                    label="Floating Installation"
                  />
                ) : null}
                <FormControlLabel
                  value={VesselContractTabEnum.Installation}
                  control={<Radio />}
                  label="Installation"
                />
                <FormControlLabel
                  value={VesselContractTabEnum.Maintenance}
                  control={<Radio />}
                  label="Maintenance"
                />
              </RadioGroup>
            </Grid>
            {viewTab === ViewTabEnum.List ? (
              <Grid
                item={true}
                style={{
                  marginLeft: "auto",
                }}
              >
                <FilterBtn count={calculateFilterCount(selectedFilter)} />
              </Grid>
            ) : null}
          </Grid>
        </FormControl>
      </Box>
      {!isLoading ? (
        <Box>
          {viewTab === ViewTabEnum.Chart ? (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid
                container={true}
                alignItems="center"
                spacing={2}
                fullWidth={true}
              >
                <Grid item={true}>
                  <FormLabel id="view-radio-buttons">Chart Type</FormLabel>
                </Grid>
                <Grid item={true}>
                  <RadioGroup
                    row={true}
                    aria-labelledby="view-radio-buttons"
                    name="view-radio-buttons"
                    value={chartTab}
                    onChange={handleChartTabChange}
                  >
                    <FormControlLabel
                      value={VesselContractChartTabEnum.ContractOverview}
                      control={<Radio />}
                      label="Contract overview"
                    />
                    <FormControlLabel
                      value={VesselContractChartTabEnum.VesselOverview}
                      control={<Radio />}
                      label="Vessel overview"
                    />
                    {tab === VesselContractTabEnum.Installation ||
                    tab === VesselContractTabEnum.CableLay ? (
                      <FormControlLabel
                        value={VesselContractChartTabEnum.LeadTime}
                        control={<Radio />}
                        label="Lead time"
                      />
                    ) : null}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Box>
          ) : undefined}
          {viewTab === ViewTabEnum.List ? (
            <VesselContractList columns={columns} data={data} />
          ) : chartTab === VesselContractChartTabEnum.ContractOverview ||
            chartTab === VesselContractChartTabEnum.VesselOverview ? (
            <VesselTimeline
              data={
                (chartTab === VesselContractChartTabEnum.VesselOverview
                  ? vesselContractChart?.[`${tab}-vessel-chart`]
                  : vesselContractChart?.[tab]) || []
              }
              legends={legends}
              projectDrawer={
                chartTab === VesselContractChartTabEnum.ContractOverview
                  ? true
                  : false
              }
              vesselDrawer={
                chartTab === VesselContractChartTabEnum.VesselOverview
                  ? true
                  : false
              }
              tab={chartTab}
              vesselDrawerMethod={vesselDrawerMethod}
            />
          ) : (
            <ContractChart data={leadTimeData || []} />
          )}
          {options ? (
            <FilterModal
              open={isFilterModalOpen}
              handleClose={() => dispatch(setFilterModal(false))}
              selectedFilter={selectedFilter?.[tab] || {}}
              onChange={(filterValues) =>
                dispatch(setVesselContractSelectedFilter({ filterValues, tab }))
              }
              options={options?.[tab]}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
