/* eslint-disable indent */
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { getErrorMessage } from "../../../helper/errorHandling";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  Country,
  GenericType,
  IdNamePair,
  LeaseArea,
  MESSAGE,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
  };
};

type Prop = {
  id?: number | null;
  onClose: () => void;
  onLeaseRoundClick: (id: number) => void;
  onBidClick: (id: number) => void;
  onLeaseClick: (id: number) => void;
};

export const LeaseAreaDrawer: FC<Prop> = ({
  id,
  onClose,
  onLeaseRoundClick,
  onLeaseClick,
}) => {
  const countries = useSelector(getCountries);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const [leaseAreaData, setLeaseAreaData] = useState<LeaseArea | null>(null);
  const [isDetailsAvailable, SetIsDetailsAvailable] = useState(false);

  const fetchLeaseAreaData = async () => {
    try {
      const { data: res } = await api.lease.getLeaseRegion(id);
      try {
        if (res?.leaseRoundInfo[0]?.leaseRoundId) {
          const { data: timeline } = await api.lease.getLeaseRoundTimeline({
            leaseRoundIds: [res?.leaseRoundInfo[0]?.leaseRoundId],
          });
          const openDate = timeline?.[0].leaseRounds.find(
            (item: GenericType) =>
              item.id === res?.leaseRoundInfo[0]?.leaseRoundId,
          ).openDate;
          if (openDate) {
            SetIsDetailsAvailable(true);
          } else {
            SetIsDetailsAvailable(false);
          }
        } else {
          SetIsDetailsAvailable(false);
        }
      } catch (err) {
        SetIsDetailsAvailable(false);
      }
      const bids = res?.leaseRoundInfo?.[0]?.bids?.map((b: GenericType) => ({
        id: b.id,
        name: b?.name,
        developers: b?.developers
          ?.map(
            (dev: GenericType) =>
              `${dev.companyShortName} ${dev?.ownershipPercentage > 0 ? dev?.ownershipPercentage + "%" : ""}`,
          )
          .filter((d: GenericType) => d)
          .join(", "),
        status: b?.status?.status,
        capacity: b?.capacity,
      }));
      const successBids = bids.filter(
        (b: GenericType) => b.status === "Successful",
      );
      setLeaseAreaData({
        ...res,
        country: countries?.find(
          (item: Country) => item.id === res?.leaseRegion?.countryId,
        )?.name,
        seaRegionName: res.leaseRegion?.seaRegion?.[0]?.name,
        leaseAreaName: res.leaseRegion?.leaseRegion?.find(
          (item: IdNamePair) => item.id === id,
        ).name,
        estimatedCapacityMW: res?.leaseRegionDetail?.estimatedCapacityMW,
        successfulDeveloper: successBids
          ?.map((b: GenericType) => b.developers)
          .join(", "),
        leaseRoundInfo: {
          ...res?.leaseRoundInfo?.[0],
          leaseAreas: res?.leaseRoundInfo?.[0]?.leaseRegion
            ?.map((item: { id: number; name: string }) => ({
              key: item.id,
              value: item.name,
            }))
            ?.sort((a: { value: string }, b: { value: string }) =>
              a.value.localeCompare(b.value),
            ),
        },
        bids,
      });
    } catch (err) {
      toast.error(`${MESSAGE.LeaseArea}: ${getErrorMessage(err)}`);
    }
  };

  const handleLeaseAreaClick = (selectedId: number) => {
    onClose();
    onLeaseClick(selectedId);
  };

  const handleLeaseRoundClick = (selectedId: number) => {
    onClose();
    onLeaseRoundClick(selectedId);
  };

  useEffect(() => {
    setLeaseAreaData(null);
    if (id) {
      fetchLeaseAreaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={leaseAreaData?.leaseAreaName || "Lease Area"}
      handleClose={onClose}
    >
      {leaseAreaData ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Basic Info"
            properties={{
              leaseAreaName: { key: "Lease area name" },
              country: { key: "Country" },
              seaRegionName: { key: "Sea region" },
            }}
            data={leaseAreaData}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Area details"
            properties={{
              estimatedCapacityMW: { key: "Estimated capacity", unit: "MW" },
              successfulDeveloper: { key: "Successful developers" },
            }}
            data={{
              estimatedCapacityMW:
                leaseAreaData.leaseRegion.leaseRegionDetail.estimatedCapacityMW,
              successfulDeveloper: leaseAreaData.successfulDeveloper,
            }}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Lease round"
            properties={{
              leaseRoundName: { key: "Lease round name" },
              ...(isDetailsAvailable && {
                leaseRoundTimeLine: {
                  key: "Timeline",
                  index: "leaseRoundId",
                  handleClick: handleLeaseRoundClick,
                },
              }),
              leaseRoundStatus: { key: "Lease round status" },
              leaseAwardDate: { key: "Award date", type: "date" },
              leaseAreas: {
                key: "Lease areas",
                handleClick: handleLeaseAreaClick,
              },
            }}
            data={leaseAreaData.leaseRoundInfo}
          />
          <Divider sx={classes.divider} />
          {leaseAreaData.bids.length ? (
            <DropDownList
              title="Bids"
              subTitleKey="name"
              name="bids"
              data={leaseAreaData.bids}
              defaultExpand={true}
              subType={{
                data: { projectNumber: leaseAreaData.bids.length },
                prop: { projectNumber: { key: "Bids number" } },
              }}
              properties={{
                name: { key: "Bid name" },
                developers: { key: "Developers" },
                status: { key: "Bid status" },
                capacity: { key: "Bid Capacity", unit: "MW" },
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
