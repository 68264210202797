import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Table, Typography } from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getList,
  getSelectedFilter,
  getSortingInfo,
} from "../../store/selector/powerOutput";
import { setRowsPerPage } from "../../store/slice/common";
import { setSelectedItem, setSortingInfo } from "../../store/slice/powerOutput";
import {
  GenericType,
  IdNamePair,
  PowerOutputEnum,
  ROUTES_CONFIG,
} from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

type Prop = {
  tab: PowerOutputEnum;
};

export const PowerOutputProjectList: FC<Prop> = ({ tab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortingInfo = useSelector(getSortingInfo);
  const itemsObject = useSelector(getList);
  const rowsPerPage = useSelector(getRowsPerPage);
  const themeMode = useSelector(getThemeMode);
  const selectedFilter = useSelector(getSelectedFilter);
  const searchText = useSelector(getSearchText);

  const title = useMemo(() => {
    return tab
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }, [tab]);

  const data = useMemo(() => {
    let list = itemsObject?.[tab];
    if (!list?.length) return [];
    list = filterDataBasedOnDynamicKeys(list, selectedFilter) as GenericType[];

    if (searchText?.length && list?.length) {
      list = list?.filter((item: GenericType) => isMatch(item, [searchText]));
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, itemsObject, selectedFilter, tab]);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Current installed capacity (MW)",
        name: "installedCapacity",
      },
      {
        label: "1-day capacity factor",
        name: "dayCapacityFactor",
      },
      {
        label: "1-week capacity factor",
        name: "weekCapacityFactor",
      },
      {
        label: "1 month capacity factor",
        name: "monthCapacityFactor",
      },
      {
        label: "YID capacity factor",
        name: "yidCapacityFactor",
      },
      {
        label: "1-year capacity factor",
        name: "yearCapacityFactor",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} ${title}`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>

          <Table
            app="wind"
            title={`Power Output ${title}`}
            columnDefs={[]}
            columns={COLUMNS}
            data={data || []}
            elevation={0}
            mode={themeMode}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `Power output data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "dropdown",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item = data?.[dataIndex];
                dispatch(setSelectedItem(item.id));
                navigate(`${ROUTES_CONFIG.PowerOutput.baseUrl}`);
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 320px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              sortThirdClickReset: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
