/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import {
  aggregateMinMaxValues,
  generateDateFilter,
  generateOptions,
  transformAttributesForFilter,
  transformNumericAttributesForFilter,
} from "../../helper/fllter";
import { validateIMONumber } from "../../helper/isMatch";
import { keyToReadableString } from "../../helper/translate";
import {
  GenericType,
  Installation,
  INSTALLATION_CAPABILITIES,
  RootState,
  SortingInfo,
  VesselInformation,
} from "../../types";

type State = {
  isLoading: boolean;
  installationList: Installation[] | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
  visibleColumns: string[];
  imoMapping: GenericType[]
};

const initialState: State = {
  isLoading: true,
  installationList: null,
  filterOptions: null,
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "vesselName",
  },
  visibleColumns: [
    "vesselName",
    "owner",
    "design",
    "craneMake",
    "craneMaxCapacity",
    "maxLiftAboveDeck",
    "deckArea",
    "dateOfDelivery",
    "newbuild",
    "vesselType",
    "status",
    "vesselCapability",
  ],
  imoMapping: []
};

export const fetchInstallationList = createAsyncThunk(
  "installation/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const list = [
        "Wind Installation Heavy Lift",
        "Wind Installation Jack Up Barge",
        "Wind Installation Barge",
        "Wind Installation Jack Up",
        "Heavy Lift Vessel",
        "General Cargo Ship"
      ];
      const vesselTypeIds = state.common.vesselType
        ?.filter((item: GenericType) => list.includes(item.type))
        .map((item: GenericType) => item.id);
      const { data } = await api.wind.getVesselInformation({
        vesselTypeIds: vesselTypeIds,
      });
      const imoMapping = data.map((v: GenericType) => ({
        imo: v?.vessel?.imo,
        bassoeId: v?.vessel?.bassoeId
      }))
      
      const tableData = data.map(
        ({ vessel, turbineContracts, cableContracts }: VesselInformation) => {
          const craneType = vessel?.cranes
            ?.map(
              (c: GenericType) =>
                c?.craneConfiguration?.craneDetails?.craneModel?.craneType?.type
            )
            .join(", ");
          const craneModel = vessel?.cranes
            ?.map(
              (c: GenericType) =>
                c?.craneConfiguration?.craneDetails?.craneModel.modelName
            )
            .join(", ");
          const filteredVesselCapability = vessel?.vesselCapabilities
            .filter((v) => INSTALLATION_CAPABILITIES.includes(v.capability))
            .map((v) => v.capability);


          let status = "";
          const today = moment();

          if (today.isBefore(moment(vessel.dateOfDelivery))) {
            status = "Under construction or ordered";
          } else if (today.isAfter(moment(vessel.dateOfDelivery))) {
            const contracts = [...turbineContracts, ...cableContracts];
            let statusSet = false;

            contracts.forEach((contract) => {
              if (
                today.isAfter(moment(contract.startDate)) &&
                today.isBefore(moment(contract.endDate))
              ) {
                status = keyToReadableString(
                  `${contract?.equipmentTypes?.[0]?.type} ${contract?.workType?.type}`
                );
                statusSet = true;
              }
            });

            if (!statusSet) {
              status = "";
            }
          } else {
            status = "";
          }
  
          const cranes = vessel?.cranes.map((c: GenericType) => ({
            craneMake:  c?.craneConfiguration?.craneDetails?.craneModel
            .manufacturingCompanyName,
            craneMaxCapacity: c?.craneConfiguration?.craneDetails?.capacityMt,
            maxLiftAboveDeck: c?.craneConfiguration?.craneDetails?.hookHeightM,
            craneInstallationDate: c?.installationDate,
            craneRemovalDate: c?.removalDate,
            longBoomWeight:  c?.craneConfiguration?.craneDetails?.longBoomMassMt,
            longBoomRadius: c?.craneConfiguration?.craneDetails?.longBoomRadiusM,
            shortBoomWeight:  c?.craneConfiguration?.craneDetails?.shortBoomMassMt,
            shortBoomRadius:  c?.craneConfiguration?.craneDetails?.shortBoomRadiusM,
            boomLength: c?.craneConfiguration?.craneDetails?.boomLengthM,
          }));

          const currentCranes: GenericType[] = [];

          cranes.forEach((crane) => {
            const craneInstallationDate = moment(crane.craneInstallationDate);
            const craneRemovalDate = crane.craneRemovalDate
              ? moment(crane.craneRemovalDate)
              : null;
    
            if (
              today.isAfter(craneInstallationDate) &&
              (!craneRemovalDate || today.isBefore(craneRemovalDate))
            ) {
              currentCranes.push(crane);
            } 
          });
          return {
            id: vessel?.imo,
            vesselName: vessel.vesselName,
            owner: vessel?.owners?.map((c) => c?.companyName).join(", "),
            design: vessel?.vesselDesign?.design,
            craneMake:  currentCranes.map((c) => c?.craneMake),
            craneMaxCapacity: currentCranes.map((c) => c?.craneMaxCapacity),
            maxLiftAboveDeck: currentCranes.map((c) => c?.maxLiftAboveDeck),
            deckArea: vessel?.vesselCapacityDetail?.deckAreaSqm,
            dateOfDelivery:
              vessel?.dateOfDelivery &&
              moment(vessel?.dateOfDelivery).format("DD-MM-YYYY"),
            newbuild: vessel?.dateOfDelivery?  moment(vessel?.dateOfDelivery)
              .startOf("day")
              .isSameOrAfter(moment().startOf("day"), "day")
              ? "Yes"
              : "No" : "Yes",
            vesselType: vessel?.vesselType?.type,
            status,
            vesselCapability: filteredVesselCapability,
            class: vessel?.classification?.class,
            imo: validateIMONumber(vessel?.imo) ? vessel?.imo : "",
            flag: vessel?.flag?.name,
            constructedYard: vessel?.shipyard?.name,
            mmsi: vessel?.mmsi,
            loa: vessel?.loa,
            futureCraneUpgrade: vessel?.futureCraneUpgrade ? "Yes" : "No",
            width: vessel?.mouldedBreadth,
            draught: vessel?.draught,
            craneType,
            craneModel,
            hookHeightAboveDeck: currentCranes.map((c) => c?.maxLiftAboveDeck),
            longBoomWeight:  currentCranes.map((c) => c?.longBoomWeight),
            longBoomRadius: currentCranes.map((c) => c?.longBoomRadius),
            shortBoomWeight: currentCranes.map((c) => c?.shortBoomWeight),
            shortBoomRadius: currentCranes.map((c) => c?.shortBoomRadius),
            boomLength: currentCranes.map((c) => c?.boomLength),
            maxDeckStrength: vessel?.vesselCapacityDetail?.deckStrengthMtSqm,
            maxDeckLoadCapacity: vessel?.vesselCapacityDetail?.deckCapacityMt,
            maxSpeed: vessel?.maximumSpeed,
            legLength: vessel?.vesselJackupDetail?.jackingLegLengthM,
            elevatingJackingSpeed: vessel?.vesselJackupDetail?.jackingSpeedMMin,
            preLoadCapacity: vessel?.vesselJackupDetail?.jackingPreloadCapMt,
            maxJackingLoad: vessel?.vesselJackupDetail?.jackingMaxLoadMt,
            jackingSystemMake:
              vessel?.vesselJackupDetail?.jackupModel?.makerCompanyName,
            jackingSystemModel: vessel?.vesselJackupDetail?.jackupModel?.model,
            maxPob: vessel?.vesselCapacityDetail?.accommodation,
            minWaterDepth: vessel?.vesselJackupDetail?.minWaterDepthM,
            maxWaterDepth: vessel?.vesselJackupDetail?.maxWaterDepthM,
          };
        }
      );
      const groups: GenericType = {
        keyInfo: [
          "vesselName",
          "owner",
          "design",
          "craneMake",
          "craneMax",
          "maxLiftAboveDeck",
          "deckArea",
          "dateOfDelivery",
          "newbuild",
          "vesselType",
          "status",
          "vesselCapability",
        ],
        vesselDetails: [
          "classification",
          "imo",
          "flag",
          "constructedYard",
          "mmsi",
          "width",
          "draught",
          "maxDeckStrength",
          "maxDeckLoadCapacity",
          "maxSpeed",
          "maxPob",
          "minWaterDepth",
          "maxWaterDepth",
        ],
        jackingDetails: [
          "preLoadCapacity",
          "legLength",
          "elevatingJackingSpeed",
          "maxJackingLoad",
          "jackingSystemMake",
          "jackingSystemModel",
        ],
        craneDetails: [
          "craneType",
          "craneModel",
          "hookHeightAboveDeck",
          "longBoomWeight",
          "longBoomRadius",
          "shortBoomWeight",
          "shortBoomRadius",
          "boomLength",
          "futureCraneUpgrade",
        ],
      };

      const numericAttributesList = [
        "craneMaxCapacity",
        "maxLiftAboveDeck",
        "deckArea",
        "width",
        "draught",
        "maxDeckStrength",
        "maxDeckLoadCapacity",
        "maxSpeed",
        "maxPob",
        "minWaterDepth",
        "maxWaterDepth",
        "preLoadCapacity",
        "legLength",
        "elevatingJackingSpeed",
        "maxJackingLoad",
        "hookHeightAboveDeck",
        "longBoomWeight",
        "longBoomRadius",
        "shortBoomWeight",
        "shortBoomRadius",
        "boomLength",
      ];

      const multiSelectAttributesList = [
        "vesselName",
        "owner",
        "design",
        "craneMake",
        "imo",
        "mmsi",
        "newbuild",
        "vesselType",
        "status",
        "vesselCapacity",
        "class",
        "flag",
        "constructedYard",
        "jackingSystemMake",
        "jackingSystemModel",
        "craneType",
        "craneModel",
        "futureCraneUpgrade",
        "vesselCapability",
      ];

      const dateAttributesList = ["dateOfDelivery"]

      const filterOptions: GenericType = {
        keyInfo: {},
        vesselDetails: {},
        jackingDetails: {},
        craneDetails: {},
      };
      let selectedAttributes: GenericType = {};
      Object.keys(groups).forEach((group) => {
        groups[group].forEach((attribute: string) => {
          if (numericAttributesList.includes(attribute)) {
            const currentAttribute = aggregateMinMaxValues(tableData, [
              attribute,
            ]);
            selectedAttributes = {
              ...selectedAttributes,
              ...currentAttribute,
            };
            filterOptions[group] = {
              ...filterOptions[group],
              ...transformNumericAttributesForFilter(currentAttribute),
            };
          } else if (multiSelectAttributesList.includes(attribute)) {
            filterOptions[group][attribute] = generateOptions(tableData, [
              attribute,
            ])[attribute];
          } else if (dateAttributesList.includes(attribute)) {
            filterOptions[group][attribute] = generateDateFilter(attribute);
          }
        });
      });
      return {
        data: tableData.map((d: GenericType) => ({
          ...d,
          vesselCapability: [...new Set(d.vesselCapability)].join(", "),
          craneMake: [...new Set(d.craneMake)].join(", "),
          craneMaxCapacity: d.craneMaxCapacity.join(", "),
          maxLiftAboveDeck: d.maxLiftAboveDeck.join(", "),
        })),
        filterOptions,
        selectedFilter: {
          ...transformAttributesForFilter(selectedAttributes),
        },
        imoMapping
      };
    } catch (err) {
      console.log(">>>", err)
      return { data: [], filterOptions: [] };
    }
  }
);

export const fetchVesselImo = createAsyncThunk(
  "fetchVesselImo",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const list = [
        "Wind Installation Heavy Lift",
        "Wind Installation Jack Up Barge",
        "Wind Installation Barge",
        "Wind Installation Jack Up",
      ];
      const vesselTypeIds = state.common.vesselType
        ?.filter((item: GenericType) => list.includes(item.type))
        .map((item: GenericType) => item.id);

      const { data } = await api.wind.getVesselInformation({
        vesselTypeIds: vesselTypeIds,
      });
      const imoMapping = data.map((v: GenericType) => ({
        imo: v?.vessel?.imo,
        bassoeId: v?.vessel?.bassoeId
      }))
      return imoMapping
    } catch (err) {
      return { data: [], filterOptions: [] };
    }
  }
);
const installationSlice = createSlice({
  name: "installation",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string | string[]>) => {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.visibleColumns = payload;
      }
      else if (state.visibleColumns.includes(payload)) {
        state.visibleColumns = state.visibleColumns.filter((col) => col !== payload);
      } else {
        state.visibleColumns.push(payload);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInstallationList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchInstallationList.rejected, (state) => {
        state.isLoading = false;
        state.installationList = null;
      })
      .addCase(fetchInstallationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.installationList = action.payload.data;
        state.filterOptions = action.payload.filterOptions;
        state.imoMapping = action.payload.imoMapping
      });
    builder
      .addCase(fetchVesselImo.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchVesselImo.rejected, (state) => {
        state.isLoading = false;
        state.imoMapping = [];
      })
      .addCase(fetchVesselImo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imoMapping = action.payload
      });
  },
});

export const { setSelectedFilter, setSortingInfo, setVisibleColumns } = installationSlice.actions;
export const installation = installationSlice.reducer;
