const {
  REACT_APP_SERVICE_API_URL,
  REACT_APP_GEOGRAPHY_API_URL,
  REACT_APP_MANUFACTURING_API_URL,
  REACT_APP_VESSEL_API_URL,
  REACT_APP_CONTENT_API_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_NOTIFICATION_API_URL,
} = process.env;

import { analytic } from "./analytics";
import { auth } from "./auth";
import { bid } from "./bid";
import { contract } from "./contract";
import { country } from "./country";
import { equipmentType } from "./equipmentType";
import { factory } from "./factory";
import { floatingTechnology } from "./floatingTechnology";
import { geographyLookup } from "./geographyLookup";
import { initApi } from "./init-api";
import { lease } from "./lease";
import { lookup } from "./lookup";
import { newsFeed } from "./newsFeed";
import { notification } from "./notification";
import { polygon } from "./polygon";
import { powerOutput } from "./powerOutput";
import { project } from "./project";
import { supplyDemand } from "./supplyDemand";
import { turbine } from "./turbine";
import { vessel } from "./vessel";
import { vesselAvailability } from "./vesselAvailability";
import { wind } from "./wind";
import { windFactoryContract } from "./windFactory";

export const api = {
  country: initApi(country, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  project: initApi(project, {
    baseURL: `${REACT_APP_SERVICE_API_URL}/Wind/WindProjects`,
  }),
  polygon: initApi(polygon, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  geographyLookup: initApi(geographyLookup, {
    baseURL: `${REACT_APP_GEOGRAPHY_API_URL}/Lookup`,
  }),
  lease: initApi(lease, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  lookup: initApi(lookup, {
    baseURL: `${REACT_APP_SERVICE_API_URL}/Lookup`,
  }),
  factory: initApi(factory, {
    baseURL: REACT_APP_MANUFACTURING_API_URL,
  }),
  bid: initApi(bid, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  turbine: initApi(turbine, {
    baseURL: `${REACT_APP_SERVICE_API_URL}/Wind`,
  }),
  floatingTechnology: initApi(floatingTechnology, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  equipmentType: initApi(equipmentType, {
    baseURL: `${REACT_APP_MANUFACTURING_API_URL}/EquipmentType`,
  }),
  vessel: initApi(vessel, {
    baseURL: REACT_APP_VESSEL_API_URL,
  }),
  analytics: initApi(analytic, {
    baseURL: REACT_APP_SERVICE_API_URL,
  }),
  newsFeed: initApi(newsFeed, {
    baseURL: REACT_APP_CONTENT_API_URL,
  }),
  supplyDemand: initApi(supplyDemand, {
    baseURL: `${REACT_APP_SERVICE_API_URL}`,
  }),
  windFactoryContract: initApi(windFactoryContract, {
    baseURL: `${REACT_APP_SERVICE_API_URL}`,
  }),
  contract: initApi(contract, {
    baseURL: `${REACT_APP_SERVICE_API_URL}`,
  }),
  vesselAvailability: initApi(vesselAvailability, {
    baseURL: `${REACT_APP_SERVICE_API_URL}/Wind`,
  }),
  wind: initApi(wind, {
    baseURL: `${REACT_APP_SERVICE_API_URL}`,
  }),
  powerOutput: initApi(powerOutput, {
    baseURL: `${REACT_APP_SERVICE_API_URL}`,
  }),
  auth: initApi(auth, {
    baseURL: `${REACT_APP_AUTH_URL}`,
  }),
  notification: initApi(notification, {
    baseURL: `${REACT_APP_NOTIFICATION_API_URL}`,
  }),
};
