import { useEffect, useMemo } from "react";
import { Box, getEsgianTheme, Typography } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { PowerOutputCompare } from "../../components/PowerOutputCompare";
import { PowerOutputHeader } from "../../components/PowerOutputHeader";
import { PowerOutputHistoricalCapacityAreaChart } from "../../components/PowerOutputHistoricalCapacityAreaChart";
import { PowerOutputKeyBox } from "../../components/PowerOutputKeyBox";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getIsLookupLoading, getThemeMode } from "../../store/selector/common";
import {
  getIsLoading,
  getSelectedItem,
  getSelectedTimePeriod,
  getTab,
} from "../../store/selector/powerOutput";
import {
  fetchList,
  fetchPowerOutput,
  setTab,
} from "../../store/slice/powerOutput";
import { PowerOutputEnum, THEME, ThemeModeEnum } from "../../types";

export const PowerOutput = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const isLookupLoading = useSelector(getIsLookupLoading);
  const isLoading = useSelector(getIsLoading);
  const tab = useSelector(getTab);
  const selectedItem = useSelector(getSelectedItem);
  const timePeriod = useSelector(getSelectedTimePeriod);

  const theme = getEsgianTheme(themeMode, THEME);

  useEffect(() => {
    if (!isLookupLoading) {
      dispatch(fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupLoading]);

  const title = useMemo(() => {
    return tab
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setTab(PowerOutputEnum.WindFarm));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchPowerOutput());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <Box sx={{ px: 2, py: 2 }}>
      {!isLoading && !isLookupLoading ? (
        <Box sx={{ overflow: "auto", height: "calc(100vh - 60px)" }}>
          <PowerOutputHeader />
          {selectedItem?.length && timePeriod ? (
            <Box>
              <Box sx={{ display: "flex", gap: 1, my: 2, height: 560 }}>
                <Box sx={{ flex: 1 }}>
                  <PowerOutputKeyBox />
                </Box>
                <Box sx={{ flex: 2, overflowX: "auto" }}>
                  <PowerOutputCompare />
                </Box>
              </Box>
              <Box
                sx={{
                  background:
                    themeMode === ThemeModeEnum.Dark
                      ? "#171717"
                      : "rgb(217, 217, 217)",
                  p: 2,
                  height: "100%",
                  borderRadius: 4,
                  color:
                    themeMode === ThemeModeEnum.Dark
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                }}
              >
                <PowerOutputHistoricalCapacityAreaChart sx={{ mt: 2 }} />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                background:
                  themeMode === ThemeModeEnum.Dark
                    ? "#171717"
                    : "rgb(217, 217, 217)",
                p: 2,
                height: 200,
                borderRadius: 4,
                my: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.white
                    : theme.palette.common.black,
              }}
            >
              <Typography variant="h6" sx={{ py: 2 }}>
                Please Select {title} and Time Period
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
