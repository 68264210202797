/* eslint-disable prettier/prettier */
import { ReactComponent as NewsfeedIcon } from "../../assets/feed.svg";
import { ReactComponent as InsightsIcon } from "../../assets/insights.svg";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { ReactComponent as FactoriesIcon } from "../../assets/schema.svg";
import { ReactComponent as VesselIcon } from "../../assets/vessel.svg";
import { ReactComponent as WindFarmIcon } from "../../assets/wind_power.svg";
import { AuthorizeUser, GenericType, ROUTES_CONFIG } from "../../types";

export const getSideMenuList = (
  routeChange: (route: string) => void,
  subSectionsOpen: GenericType,
  subItemCloseOrOpen: (item: GenericType) => void,
  isAuthenticated: AuthorizeUser
) => {
  const menuList = [
    {
      key: "wind-farm",
      icon: <WindFarmIcon color="inherit" />,
      text: "Wind farms",
      subSectionOpen: subSectionsOpen["wind-farm"],
      onClick: () => {
        subItemCloseOrOpen({ "wind-farm": !subSectionsOpen["wind-farm"] });
      },
      subSections: [
        {
          key: "target",
          text: "Targets",
          onClick: () => {
            routeChange(ROUTES_CONFIG.Target.url);
          },
        },
        {
          key: "lease-round-timeline",
          text: "Lease rounds",
          onClick: () => {
            routeChange(ROUTES_CONFIG.LeaseRoundTimeLine.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        {
          key: "leasing-and-projects",
          text: "Projects",
          onClick: () => {
            routeChange(ROUTES_CONFIG.WindLeasingAndProject.url);
          },
        },
      ],
    },
    {
      key: "equipment",
      icon: <FactoriesIcon color="inherit" />,
      text: "Equipment",
      subSectionOpen: subSectionsOpen.equipment,
      onClick: () => {
        subItemCloseOrOpen({ equipment: !subSectionsOpen.equipment });
      },
      subSections: [
        {
          key: "factory",
          text: "Factories",
          onClick: () => {
            routeChange(ROUTES_CONFIG.Factory.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        {
          key: "turbine-model",
          text: "Turbine models",
          onClick: () => {
            routeChange(ROUTES_CONFIG.TurbineModel.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        {
          key: "floating-technology",
          text: "Floating technology",
          onClick: () => {
            routeChange(ROUTES_CONFIG.FloatingTechnology.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
      ],
    },
    {
      key: "vessels",
      icon: <VesselIcon color="inherit" />,
      text: "Vessels",
      subSectionOpen: subSectionsOpen.vessels,
      onClick: () => {
        subItemCloseOrOpen({ vessels: !subSectionsOpen.vessels });
      },
      subSections: [
        {
          key: "installation",
          text: "Installation",
          onClick: () => {
            routeChange(ROUTES_CONFIG.Installation.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        {
          key: "cable-lay",
          text: "Cable lay",
          onClick: () => {
            routeChange(ROUTES_CONFIG.CableLay.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
      ],
    },
    {
      key: "insights",
      icon: <InsightsIcon color="inherit" />,
      text: "Insights",
      subSectionOpen: subSectionsOpen.insights,
      onClick: () => {
        subItemCloseOrOpen({ insights: !subSectionsOpen.insights });
      },
      subSections: [
        {
          key: "analytics",
          text: "Analytics",
          onClick: () => {
            routeChange(ROUTES_CONFIG.Analytics.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        {
          key: "contracts",
          text: "Contracts",
          onClick: () => {
            routeChange(ROUTES_CONFIG.Contract.url);
          },
          endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
        },
        process.env?.REACT_APP_VESSEL_AVAILABILITY_FEATURE_FLAG 
          ? {
            key: "installation-vessel-availability",
            text: "Vessel availability",
            onClick: () => {
              routeChange(ROUTES_CONFIG.InstallationVesselAvailability.url);
            },
            endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
          } 
          : null,
      ].filter(Boolean), 
    },
    {
      key: "newsfeed",
      icon: <NewsfeedIcon color="inherit" />,
      text: "Analysis",
      disableSearch: true,
      onClick: () => {
        routeChange(ROUTES_CONFIG.NewsFeed.url);
      },
      endIcon: isAuthenticated === AuthorizeUser.NonAuthorized ? <LockIcon color="inherit" /> : null
    },
  ].filter(Boolean); 

  return menuList;
};
