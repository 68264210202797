import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import {
  aggregateMinMaxValues,
  transformNumericAttributesForFilter,
} from "../../helper/fllter";
import {
  GenericType,
  IdNamePair,
  PowerOutputEnum,
  PowerOutputPeriodTypeEnum,
  SelectedFilterItem,
  SortingInfo,
} from "../../types";

type PowerOutputData = {
  [key in PowerOutputEnum]?: GenericType[];
};

type State = {
  isLoading: boolean;
  tab: PowerOutputEnum;
  selectedItem: number[];
  timePeriod: SelectedFilterItem | null;
  list: PowerOutputData | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
  historicalCapacityLading: boolean;
  historicalCapacity: GenericType | null;
  keyInfo: GenericType | null;
  dateRange: {
    startDate: string;
    endDate: string;
    yAxisType: string;
  } | null;
};

const initialTimePeriod = { title: "1-day", id: 1 };
const initialState: State = {
  isLoading: false,
  tab:
    (localStorage.getItem("powerModuleSelectedTab") as PowerOutputEnum) ||
    PowerOutputEnum.WindFarm,
  selectedItem:
    JSON.parse(localStorage.getItem("powerModuleSelectedItem") as string) || [],
  timePeriod: initialTimePeriod,
  filterOptions: null,
  list: null,
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "vesselName",
  },
  historicalCapacityLading: true,
  historicalCapacity: null,
  keyInfo: null,
  dateRange: null,
};

export const fetchList = createAsyncThunk("fetchList", async () => {
  try {
    const { data } = await api.powerOutput.getPowerOutputLookup();

    const mapCapacityData = (items: GenericType[]) =>
      items.map((item: GenericType) => ({
        id: item?.id,
        name: item?.name,
        installedCapacity: Number(item?.installedCapacity?.toFixed(2)),
        dayCapacityFactor: Number(item?.dailyAvg?.toFixed(2)),
        weekCapacityFactor: Number(item?.weeklyAvg?.toFixed(2)),
        monthCapacityFactor: Number(item?.monthlyAvg?.toFixed(2)),
        yidCapacityFactor: Number(item?.ytdAvg?.toFixed(2)),
        yearCapacityFactor: Number(item?.yearlyAvg?.toFixed(2)),
      }));

    const windFarm = mapCapacityData(data.windProjectCapacity);
    const turbine = mapCapacityData(data.turbineModelCapacity);
    const developer = mapCapacityData(data.developerCapacity);
    const country = mapCapacityData(data.countryCapacity);
    const controlArea = mapCapacityData(data.controlAreaCapacity);

    const attributesList = [
      "installedCapacity",
      "dayCapacityFactor",
      "weekCapacityFactor",
      "monthCapacityFactor",
      "yidCapacityFactor",
      "yearCapacityFactor",
    ];

    const allData = [
      ...turbine,
      ...windFarm,
      ...developer,
      ...country,
      ...controlArea,
    ];
    const attributes = aggregateMinMaxValues(allData, attributesList);

    return {
      data: {
        [PowerOutputEnum.WindFarm]: windFarm,
        [PowerOutputEnum.TurbineSupplier]: turbine,
        [PowerOutputEnum.Developer]: developer,
        [PowerOutputEnum.Country]: country,
        [PowerOutputEnum.ControlArea]: controlArea,
      },
      filterOptions: transformNumericAttributesForFilter(attributes),
      selectedFilter: {},
    };
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const fetchPowerOutput = createAsyncThunk(
  "fetchPowerOutput",
  async (_, { getState }) => {
    try {
      const state = getState() as GenericType;
      const tab = state?.powerOutput?.tab;
      const selectedItem = state?.powerOutput?.selectedItem;
      const dateRange = state?.powerOutput?.dateRange;
      if (selectedItem?.length && dateRange?.startDate && dateRange?.endDate) {
        const payload: GenericType = {
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
          calculationType: PowerOutputPeriodTypeEnum[dateRange?.yAxisType],
        };
        if (tab === PowerOutputEnum.WindFarm) {
          payload.windProjectIds = selectedItem;
        }
        if (tab === PowerOutputEnum.TurbineSupplier) {
          payload.turbineModelIds = selectedItem;
        }
        const { data } = await api.powerOutput.getPowerOutput(payload);
        const findFirstItem = data?.find(
          (item: GenericType) =>
            item?.windProject?.windProjectId === selectedItem[0],
        );
        const keyInfo: GenericType = {
          capacityMW: findFirstItem?.windProject?.capacityMW,
          controlArea: findFirstItem?.windProject?.controlArea?.name,
          turbineSize: null,
          numberOfTurbine: null,
          turbineSupplier: null,
          turbineModel: findFirstItem?.windProject?.turbineModels
            ?.map((t: IdNamePair) => t.name)
            .join(","),
        };
        const historicalCapacity = data?.map((item: GenericType) => ({
          name: item?.windProject?.name,
          values: item?.powerGeneration?.map((pow: GenericType) => ({
            date: pow?.date,
            value: pow?.capacity,
          })),
        }));
        return { historicalCapacity, keyInfo };
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  },
);

const powerOutputSlice = createSlice({
  name: "powerOutput",
  initialState,
  reducers: {
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
    setTab: (state, action: PayloadAction<PowerOutputEnum>) => {
      localStorage.setItem("powerModuleSelectedTab", action.payload);
      localStorage.removeItem("powerModuleSelectedItem");
      state.tab = action.payload;
      state.timePeriod = initialTimePeriod;
      state.selectedItem = [];
    },
    setNewSelectedItem: (state, action: PayloadAction<number>) => {
      state.selectedItem = [action?.payload];
      localStorage.setItem(
        "powerModuleSelectedItem",
        JSON.stringify([action?.payload]),
      );
    },
    setSelectedItem: (state, action: PayloadAction<number>) => {
      const index = state.selectedItem.indexOf(action?.payload);
      const list = state.selectedItem;
      if (index >= 0) {
        list.splice(index, 1);
      } else {
        list.push(action.payload);
      }
      state.selectedItem = list;
      localStorage.setItem("powerModuleSelectedItem", JSON.stringify(list));
    },
    setTimePeriod: (state, action: PayloadAction<SelectedFilterItem>) => {
      localStorage.setItem(
        "powerModuleTimePeriod",
        action.payload as unknown as string,
      );
      state.timePeriod = action.payload;
    },
    setDateRange: (
      state,
      action: PayloadAction<{
        startDate: string;
        endDate: string;
        yAxisType: string;
      } | null>,
    ) => {
      state.dateRange = action.payload;
    },
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isLoading = false;
        state.list = null;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        if (action.payload) {
          state.list = action.payload.data;
          state.filterOptions = action.payload.filterOptions;
          state.selectedFilter = action.payload.selectedFilter;
        }
        state.isLoading = false;
      });
    builder
      .addCase(fetchPowerOutput.pending, function (state) {
        state.historicalCapacityLading = true;
      })
      .addCase(fetchPowerOutput.rejected, (state) => {
        state.historicalCapacityLading = false;
        state.historicalCapacity = null;
      })
      .addCase(fetchPowerOutput.fulfilled, (state, action) => {
        state.historicalCapacity = action.payload?.historicalCapacity;
        state.keyInfo = action.payload?.keyInfo || null;
        state.historicalCapacityLading = false;
      });
  },
});

export const {
  setSortingInfo,
  setTab,
  setSelectedItem,
  setNewSelectedItem,
  setSelectedFilter,
  setTimePeriod,
  setDateRange,
} = powerOutputSlice.actions;
export const powerOutput = powerOutputSlice.reducer;
