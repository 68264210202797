import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LngLatLike } from "mapbox-gl";

import { api } from "../../api/api";
import { safelyCallApi } from "../../helper/requestWrapper";
import {
  AuthorizeUser,
  CompanyOption,
  Country,
  GenericType,
  Lookup,
  Option,
  ThemeModeEnum,
  ViewTabEnum,
} from "../../types";

type State = {
  enquirySent: boolean;
  isAuthenticated: AuthorizeUser;
  selectedCountryName: string | null;
  selectedLeasAreaId: number | null;
  selectedLeasRoundId: number | null;
  selectedProjectId: number | null;
  selectedBidId: number | null;
  selectedFactoryId: number | null;
  selectedTurbineModelId: number | null;
  selectedFloatingTechnologyId: number | null;
  selectedInstallationId: number | null;
  selectedCableLayId: number | null;
  themeMode: ThemeModeEnum;
  viewTab: ViewTabEnum;
  sideMenu: boolean;
  isLoading: boolean;
  isLookupLoading: boolean;
  globalError: boolean;
  countries: Country[] | [];
  seaArea: Lookup[] | [];
  leaseArea: Lookup[] | [];
  landArea: Lookup[] | [];
  leaseRoundStatus: Option[] | [];
  leaseRound: Option[] | [];
  leaseRoundType: Option[] | [];
  projects: Option[] | [];
  projectType: Option[] | [];
  projectStatus: Option[] | [];
  equipmentType: Option[] | [];
  factoryFunctions: Option[] | [];
  factoryStatus: Option[] | [];
  company: CompanyOption[] | [];
  isFilterModalOpen: boolean;
  searchText?: string;
  userLocationCoordinates: LngLatLike | null;
  searchObject: Option | null;
  rowsPerPage: number;
  vesselType: GenericType | null;
};

const initialState: State = {
  enquirySent: !!localStorage.getItem("enquirySent") || false,
  isAuthenticated: AuthorizeUser.Default,
  rowsPerPage: Number(localStorage.getItem("rowsPerPage") || 25),
  selectedCountryName: null,
  selectedLeasAreaId: null,
  selectedLeasRoundId: null,
  selectedProjectId: null,
  selectedBidId: null,
  selectedFactoryId: null,
  selectedFloatingTechnologyId: null,
  selectedInstallationId: null,
  selectedCableLayId: null,
  selectedTurbineModelId: null,
  themeMode:
    (localStorage.getItem("themeMode") as ThemeModeEnum) || ThemeModeEnum.Dark,
  viewTab: ViewTabEnum.Map,
  sideMenu: true,
  isLoading: false,
  isLookupLoading: true,
  globalError: false,
  countries: [],
  seaArea: [],
  leaseArea: [],
  landArea: [],
  leaseRoundStatus: [],
  leaseRound: [],
  leaseRoundType: [],
  projects: [],
  projectType: [],
  projectStatus: [],
  equipmentType: [],
  factoryFunctions: [],
  factoryStatus: [],
  company: [],
  isFilterModalOpen: false,
  searchText: undefined,
  userLocationCoordinates: null,
  searchObject: null,
  vesselType: null,
};

export const fetchLookup = createAsyncThunk(
  "lookup",
  async (itemsToFetch: string[], { getState }) => {
    const state = getState() as GenericType;
    const promises: Promise<GenericType>[] = [];
    const resultKeys = [
      "leaseRoundStatus",
      "leaseRoundType",
      "projectStatus",
      "countries",
      "landArea",
      "seaArea",
      "projects",
      "projectType",
      "leaseArea",
      "leaseRound",
      "equipmentType",
      "company",
      "factoryFunctions",
      "factoryStatus",
      "vesselType",
    ];

    const apiCalls: GenericType = {
      leaseRoundStatus: () => safelyCallApi(api.lookup.getLeaseRoundStatus()),
      leaseRoundType: () => safelyCallApi(api.lookup.getLeaseRoundType()),
      projectStatus: () => safelyCallApi(api.lookup.getProjectStatus()),
      countries: () => safelyCallApi(api.country.getCountryList()),
      landArea: () => safelyCallApi(api.geographyLookup.getRegions([6, 7])),
      seaArea: () => safelyCallApi(api.geographyLookup.getRegions([5])),
      projects: () => safelyCallApi(api.lookup.getProjects()),
      projectType: () => safelyCallApi(api.lookup.getProjectType()),
      leaseArea: () => safelyCallApi(api.geographyLookup.getRegions([3])),
      leaseRound: () => safelyCallApi(api.lookup.getLeaseRound()),
      equipmentType: () =>
        safelyCallApi(api.equipmentType.getEquipmentType({})),
      company: () => safelyCallApi(api.lookup.getCompanies()),
      factoryFunctions: () =>
        safelyCallApi(api.factory.getFactoryFunctions({})),
      factoryStatus: () => safelyCallApi(api.factory.getFactoryStatus()),
      vesselType: () => safelyCallApi(api.vessel.getVesselType()),
    };
    resultKeys.forEach((key) => {
      if (itemsToFetch.includes(key) && !state.common[key]?.length) {
        promises.push(apiCalls[key]());
      }
    });

    const results = await Promise.all(promises);
    const data: GenericType = {};
    resultKeys.forEach((key) => {
      if (itemsToFetch.includes(key)) {
        data[key] = results.shift()?.data || state.common[key];
      }
    });
    return data;
  },
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<AuthorizeUser>) => {
      state.isAuthenticated = action.payload;
    },
    setSelectedCountryName: (state, action: PayloadAction<string | null>) => {
      state.selectedCountryName = action.payload;
    },
    setSelectedLeaseAreaId: (state, action: PayloadAction<number | null>) => {
      state.selectedLeasAreaId = action.payload;
    },
    setSelectedLeaseRoundId: (state, action: PayloadAction<number | null>) => {
      state.selectedLeasRoundId = action.payload;
    },
    setSelectedProjectId: (state, action: PayloadAction<number | null>) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedBidId: (state, action: PayloadAction<number | null>) => {
      state.selectedBidId = action.payload;
    },
    setSelectedFactoryId: (state, action: PayloadAction<number | null>) => {
      state.selectedFactoryId = action.payload;
    },
    setSelectedInstallationId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.selectedInstallationId = action.payload;
    },
    setSelectedCableLayId: (state, action: PayloadAction<number | null>) => {
      state.selectedCableLayId = action.payload;
    },
    setSelectedFloatingTechnologyId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.selectedFloatingTechnologyId = action.payload;
    },
    setSelectedTurbineModelId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.selectedTurbineModelId = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<ThemeModeEnum>) => {
      localStorage.setItem("themeMode", action.payload);
      state.themeMode = action.payload;
    },
    setViewTab: (state, action: PayloadAction<ViewTabEnum>) => {
      state.viewTab = action.payload;
    },
    setSideMenu: (state, action: PayloadAction<boolean>) => {
      state.sideMenu = action.payload;
    },
    setFilterModal: (state, action: PayloadAction<boolean>) => {
      state.isFilterModalOpen = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string | undefined>) => {
      state.searchText = action.payload;
    },
    setUserLocationCoordinates: (
      state,
      action: PayloadAction<[number, number]>,
    ) => {
      state.userLocationCoordinates = action.payload;
    },
    setSearchObjectCoordinates: (
      state,
      action: PayloadAction<Option | null>,
    ) => {
      state.searchObject = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      localStorage.setItem("rowsPerPage", action.payload.toString());
    },
    setEnquirySent: (state, action: PayloadAction<boolean>) => {
      state.enquirySent = action.payload;
      localStorage.setItem("enquirySent", action.payload.toString());
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLookup.pending, function (state) {
        state.isLookupLoading = true;
      })
      .addCase(fetchLookup.rejected, (state) => {
        state.globalError = true;
        state.isLookupLoading = false;
      })
      .addCase(fetchLookup.fulfilled, (state: GenericType, action) => {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
        state.isLookupLoading = false;
      });
  },
});

export const {
  setSelectedCountryName,
  setSelectedLeaseAreaId,
  setSelectedProjectId,
  setSelectedBidId,
  setSelectedLeaseRoundId,
  setSelectedFactoryId,
  setSelectedTurbineModelId,
  setSelectedFloatingTechnologyId,
  setSelectedInstallationId,
  setSelectedCableLayId,
  setThemeMode,
  setViewTab,
  setSideMenu,
  setFilterModal,
  setSearchText,
  setUserLocationCoordinates,
  setSearchObjectCoordinates,
  setRowsPerPage,
  setIsAuthenticated,
  setEnquirySent,
} = commonSlice.actions;

export const common = commonSlice.reducer;
