/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@esgian/esgianui";
import { Eventcalendar, MbscEventcalendarView, Popup } from "@mobiscroll/react";
import moment from "moment";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getLeaseRoundTimelineEvents,
  getLeaseRoundTimelineStartAndEndDate,
} from "../../store/selector/leaseRoundTimeline";
import {
  setSelectedBidId,
  setSelectedLeaseRoundId,
} from "../../store/slice/common";
import {
  GenericType,
  HoverEventParam,
  Resource,
  ResourceEvent,
} from "../../types";
import { TimeLineEvent } from "..";

type Prop = {
  viewType: "year" | "month";
  resources: Resource[];
  collapsedAll: boolean;
  setCollapsedAll: (v: boolean) => void;
};

const popupMapping: GenericType = {
  "accepting-proposals-dot": {
    title: "Accepting Proposals from",
    key: "start",
  },
  "closed-dot": {
    title: "Closed after",
    key: "closedDate",
  },
  "completed-dot": {
    title: "Completed on",
    key: "end",
  },
  "submitted-bar": {
    title: "Submitted",
    key: "end",
  },
  "winner-bar": {
    title: "Winner",
    key: "end",
  },
  "unsuccessful-bar": {
    title: "Unsuccessful",
    key: "end",
  },
  "withdrawn-bar": {
    title: "Withdrawn",
    key: "end",
  },
  "successful-bar": {
    title: "Successful",
    key: "end",
  },
  proposed: {
    title: "Proposed bid",
    key: "end",
  },
};

export const LeaseRoundTimeLine: FC<Prop> = ({
  viewType,
  resources,
  setCollapsedAll,
  collapsedAll,
}) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const startAndEndDate = useSelector(getLeaseRoundTimelineStartAndEndDate);
  const events = useSelector(getLeaseRoundTimelineEvents);
  const [popupTarget, setPopupTarget] = useState<HoverEventParam | null>(null);

  const view = useMemo<MbscEventcalendarView>(() => {
    let stepCount;
    if (viewType === "year") {
      stepCount = 4;
    } else {
      stepCount = 48;
    }
    return {
      timeline: {
        currentTimeIndicator: true,
        resolutionHorizontal: viewType,
        type: viewType,
        size: stepCount,
      },
    };
  }, [viewType, startAndEndDate]);

  const onClickItem = (level: number, id: number) => {
    if (level === 2) {
      dispatch(setSelectedLeaseRoundId(id));
    } else if (level === 3) {
      dispatch(setSelectedBidId(id));
    }
  };

  const renderMyResource = useCallback((resource: Resource) => {
    return (
      <Box
        sx={{
          color: resource.level > 1 ? "#0E9EFF" : undefined,
          cursor: resource.level > 1 ? "pointer" : undefined,
          p: "0 !important",
        }}
        className={`${resource.level === 1 && "country-resource-title"}`}
        onClick={() => onClickItem(resource.level, resource.itemId)}
      >
        <Typography
          sx={{
            pl: resource.level > 2 ? 7 : 5,
          }}
        >
          {resource.name}
        </Typography>
      </Box>
    );
  }, []);

  const findCountryRows = () => {
    const elements = document.querySelectorAll(".country-resource-title") || [];
    elements.forEach((element) => {
      const parentElement = element.closest(".mbsc-timeline-resource");
      if (parentElement) {
        parentElement.classList.add("country-timeline-row");
      }
    });
  };

  const findLeaseAreaRows = () => {
    const elements =
      document.querySelectorAll(".lease-area-resource-title") || [];
    elements.forEach((element) => {
      const parentElement = element.closest(".mbsc-timeline-resource");
      if (parentElement) {
        parentElement.classList.add("lease-area-timeline-row");
      }
    });
  };

  const onHover = (param: HoverEventParam | null) => {
    if (param) {
      const { type, target, name } = param;
      const hoveredEvents = events?.find(
        (event) => event.resource === param.resource,
      ) as GenericType;
      if (!name) return setPopupTarget(null);
      const mapping = popupMapping[name];
      const popupData = {
        title: mapping?.title,
        date: name && hoveredEvents ? hoveredEvents[mapping?.key] : null,
        bidName: hoveredEvents?.bidName,
        companies: hoveredEvents?.companies
          ?.map((i: GenericType) => i.companyName)
          .join(", "),
      };
      return setPopupTarget({
        name,
        type,
        target,
        popupData,
        resource: param.resource,
      });
    }
    setPopupTarget(null);
  };

  useEffect(() => {
    findLeaseAreaRows();
  }, [collapsedAll]);

  useEffect(() => {
    findCountryRows();
  }, []);

  useEffect(() => {
    const currentTimeIndicator = document.querySelector(
      ".mbsc-schedule-time-indicator-time",
    );
    if (currentTimeIndicator) {
      const setTodayText = () => {
        currentTimeIndicator.textContent = "Today";
      };

      setTodayText();

      // Set up a timer to reset the text every minute
      const intervalId = setInterval(setTodayText, 100);

      // Clean up interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <>
      <Eventcalendar
        class="calendar"
        theme="material"
        title="Today"
        themeVariant={themeMode}
        view={view}
        data={events}
        refDate={startAndEndDate.startDate || new Date(2022, 1, 1)}
        renderResource={renderMyResource}
        resources={resources}
        onResourceExpand={() => setCollapsedAll(false)}
        onResourceCollapse={() => setCollapsedAll(true)}
        // showControls={false}
        showEventTooltip={false}
        renderScheduleEvent={(eventData: ResourceEvent) => (
          <TimeLineEvent
            resource={eventData.resource as string}
            startDate={eventData.startDate}
            endDate={eventData.endDate}
            closeDate={eventData.original.closedDate}
            level={eventData.currentResource.level}
            status={eventData.original.status}
            estimated={eventData.original?.estimated}
            onHover={onHover}
          />
        )}
      />
      <Popup
        display="anchored"
        isOpen={!!popupTarget}
        anchor={popupTarget?.target || undefined}
        touchUi={false}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        themeVariant={themeMode}
      >
        <Box sx={{ my: 1, mx: 2 }}>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {popupTarget?.popupData?.title}
            </Typography>
            {popupTarget?.type === "Dot" ? (
              <Typography sx={{ fontSize: 14, fontWeight: 400, mt: 1 }}>
                {moment(popupTarget?.popupData?.date).format("YYYY-MM-DD")}
              </Typography>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {popupTarget?.popupData?.title} date :
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400, ml: 2 }}>
                    {moment(popupTarget?.popupData?.date).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    Bid :
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400, ml: 2 }}>
                    {popupTarget?.popupData?.bidName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    Company :
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400, ml: 2 }}>
                    {popupTarget?.popupData?.companies}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Popup>
    </>
  );
};
