import { FC } from "react";
import { Box, Button, List, ListItem, Typography } from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as DetailsIcon } from "../../../assets/details.svg";
import { useAuth } from "../../../hooks/use-auth";
import { GenericType } from "../../../types";
import { BlurredComponent } from "../../Blurred";
import DottedList from "../DottedList.tsx";

const styles = {
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 600,
  },
  listItem: {
    paddingTop: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  shareBtn: {
    display: "flex",
    alignItems: "center",
    minHeight: 0,
    minWidth: 0,
    padding: 0,
  },
  valueItem: {
    textAlign: "right",
  },
};

type Prop = {
  title: string;
  handleTitleClick?: {
    handleClick: (id: number) => void;
    index: string;
  };
  data: GenericType;
  properties: GenericType;
  handleDetailsClick?: (id: number) => void;
};

const Paragraph: FC<Prop> = ({ title, handleTitleClick, data, properties }) => {
  const { isAuthenticated } = useAuth();
  const renderProperty = (propertyKey: string) => {
    const property = properties[propertyKey];
    const { handleClick, type, unit, mathUnit, publicItem } = property;
    if (handleClick) {
      return (
        <BlurredComponent
          isAuthenticated={isAuthenticated}
          publicItem={publicItem}
        >
          <Button
            variant="text"
            size="small"
            sx={styles.shareBtn}
            onClick={() => handleClick(data[properties[propertyKey].index])}
            endIcon={<DetailsIcon />}
          >
            Details
          </Button>
        </BlurredComponent>
      );
    } else {
      return (
        <BlurredComponent
          isAuthenticated={isAuthenticated}
          publicItem={publicItem}
        >
          <Typography variant="body2" sx={styles.valueItem}>
            {data[propertyKey] || data[propertyKey] === 0
              ? type === "date"
                ? moment(data[propertyKey]).format("DD-MM-YYYY")
                : `${data[propertyKey]}${unit ? ` ${unit}` : ""}`
              : ""}
            {data[propertyKey] ? <sup>{mathUnit}</sup> : null}
          </Typography>
        </BlurredComponent>
      );
    }
  };

  return (
    <Box key={title}>
      <Box sx={styles.titleBar}>
        <Typography>{title}</Typography>

        {handleTitleClick && (
          <Button
            variant="text"
            size="small"
            sx={styles.shareBtn}
            onClick={handleTitleClick}
            endIcon={<DetailsIcon />}
          >
            Details
          </Button>
        )}
      </Box>
      <List disablePadding={true}>
        {Object.keys(properties).map((propertyKey, index) => (
          <ListItem
            disablePadding={true}
            key={index}
            sx={{
              ...styles.listItem,
              paddingBottom: properties[propertyKey]?.spaceBottom,
            }}
          >
            {Array.isArray(data[propertyKey]) ? (
              <DottedList
                data={data[propertyKey]}
                title={propertyKey.split(/(?=[A-Z])/).join(" ")}
                displayIndex={false}
                displayDetailedBtn={true}
                titleVariant="body2"
                handleClick={properties[propertyKey].handleClick}
              />
            ) : (
              <>
                <Typography variant="body2" sx={{ minWidth: 110 }}>
                  {properties[propertyKey].key}
                </Typography>
                {renderProperty(propertyKey)}
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Paragraph;
