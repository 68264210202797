import { toast } from "react-toastify";

import {
  CompanyOption,
  Country,
  GenericType,
  Lookup,
  MESSAGE,
  Option,
  RootState,
} from "../../types";

export const getSelectedCountry = (state: RootState) => {
  const country = state.common?.countries?.find(
    (item) => item.name === state.common.selectedCountryName,
  );
  if (state.common.selectedCountryName && !country) {
    toast.warning(`${MESSAGE.NoInfo} ${state.common.selectedCountryName}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return country;
};

export const getSelectedTurbineModelId = (state: RootState) => {
  return state.common.selectedTurbineModelId;
};

export const getIsLookupLoading = (state: RootState) => {
  return state.common.isLookupLoading;
};

export const getSelectedFloatingTechnologyId = (state: RootState) => {
  return state.common.selectedFloatingTechnologyId;
};

export const getSelectedFactoryId = (state: RootState) => {
  return state.common.selectedFactoryId;
};

export const getSelectedLeaseAreaId = (state: RootState) => {
  return state.common.selectedLeasAreaId;
};

export const getSelectedLeaseRoundIs = (state: RootState) => {
  return state.common.selectedLeasRoundId;
};

export const getSelectedProjectId = (state: RootState) => {
  return state.common.selectedProjectId;
};

export const getSelectedBidId = (state: RootState) => {
  return state.common.selectedBidId;
};

export const getSelectedInstallationId = (state: RootState) => {
  return state.common.selectedInstallationId;
};

export const getSelectedCableLayId = (state: RootState) => {
  return state.common.selectedCableLayId;
};

export const getThemeMode = (state: RootState) => {
  return state.common.themeMode;
};

export const getViewTab = (state: RootState) => {
  return state.common.viewTab;
};

export const getSideMenu = (state: RootState) => {
  return state.common.sideMenu;
};

export const getCountries = (state: RootState) => {
  return state.common.countries;
};

export const getSearchObject = (state: RootState) => {
  return state.common.searchObject;
};

export const getOptions = (state: RootState) => {
  const options: GenericType = {};

  if (state?.common?.countries.length) {
    const countries = state?.common?.countries
      ?.map((item: Country) => ({
        id: item.id,
        value: item.id,
        title: item.name,
        type: "Location",
        subtype: "country",
        centroidCoordinates: item.centroidCoordinates,
      }))
      ?.filter(
        (item) =>
          item?.centroidCoordinates?.lat && item?.centroidCoordinates?.lon,
      );
    options.countries = countries;
  }

  if (state?.common?.seaArea.length) {
    const seaArea = state?.common?.seaArea
      ?.map((item: Lookup) => ({
        id: item.regionId,
        value: item.regionId,
        title: item.regionName,
        type: "Location",
        subtype: "sea region",
        centroidCoordinates: item.centroidCoordinates,
      }))
      .filter(
        (item) =>
          item?.centroidCoordinates?.lat && item?.centroidCoordinates?.lon,
      );
    options.seaArea = seaArea;
  }

  if (state?.common?.landArea.length) {
    const landArea = state?.common?.landArea
      ?.map((item: Lookup) => ({
        id: item.regionId,
        value: item.regionId,
        title: item.regionName,
        type: "Location",
        subtype: "other region types",
        centroidCoordinates: item.centroidCoordinates,
      }))
      .filter(
        (item) =>
          item?.centroidCoordinates?.lat && item?.centroidCoordinates?.lon,
      );
    options.landArea = landArea;
  }

  if (state?.common?.leaseArea.length) {
    const leaseArea = state?.common?.leaseArea
      ?.map((item: Lookup) => ({
        id: item.regionId,
        value: item.regionId,
        title: item.regionName,
        type: "Lease area",
        centroidCoordinates: item.centroidCoordinates,
      }))
      .filter(
        (item) =>
          item?.centroidCoordinates?.lat && item?.centroidCoordinates?.lon,
      );
    options.leaseArea = leaseArea;
  }

  if (state?.common?.leaseRound.length) {
    const leaseRound = state?.common?.leaseRound
      ?.map((item: Option) => ({
        id: item.id,
        value: item.id,
        title: item.name,
        type: "Lease round",
        centroidCoordinates: item.centroidCoordinates,
      }))
      .filter(
        (item) =>
          item?.title &&
          item?.centroidCoordinates?.lat &&
          item?.centroidCoordinates?.lon,
      );
    options.leaseRound = leaseRound;
  }
  if (state?.common?.projects.length) {
    const projects = state?.common?.projects
      .map((item: Option) => ({
        id: item.id,
        type: "project",
        title: item.name,
        value: item.assetId,
        centroidCoordinates: item.coordinates,
      }))
      .filter(
        (item) =>
          item?.centroidCoordinates?.lat && item?.centroidCoordinates?.lon,
      );
    options.projects = projects;
  }
  if (state?.common?.company.length) {
    const company = state?.common?.company
      .map((item: CompanyOption) => ({
        id: item?.company?.id,
        value: item?.company?.id,
        type: "Company",
        title: item?.company?.companyName,
        factoryCount: item?.factories?.length,
        substationCount: item?.substations?.length,
        windProjectCount: item?.windProjects?.length,
      }))
      .filter((item) => item?.id && item?.title);
    options.company = company;
  }

  return options;
};

export const getCompany = (state: RootState) => {
  return state.common.company;
};

export const getProjectStatus = (state: RootState) => {
  return state.common.projectStatus;
};
export const getFilterModal = (state: RootState) => {
  return state.common.isFilterModalOpen;
};

export const getGlobalError = (state: RootState) => {
  return state.common.globalError;
};

export const getSearchText = (state: RootState) => {
  return state.common.searchText;
};

export const getUserLocationCoordinates = (state: RootState) => {
  return state.common.userLocationCoordinates;
};

export const getRowsPerPage = (state: RootState) => {
  return state.common.rowsPerPage;
};

export const getVesselType = (state: RootState) => {
  return state.common.vesselType;
};

export const getProjects = (state: RootState) => {
  return state.common.projects;
};

export const getIsAuthenticated = (state: RootState) => {
  return state.common.isAuthenticated;
};

export const getEnquirySent = (state: RootState) => {
  return state.common.enquirySent;
};
