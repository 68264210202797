import { FC, useEffect, useState } from "react";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  // ContractDetails,
  Country,
  GenericType,
  IdNamePair,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const FactoryDrawer: FC<{
  id?: number | null;
  onClose: () => void;
}> = ({ id, onClose }) => {
  const countries = useSelector(getCountries);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const [data, setData] = useState<GenericType | null>(null);

  const fetchFactoryData = async (factoryId: number) => {
    try {
      const { data: resp } = await api.windFactoryContract.getFactoryInfo({
        ids: [factoryId],
      });
      const res = resp[0];
      const result = {
        keyInfo: {
          factoryId: factoryId,
          factoryName: res?.factoryInformation?.name,
          factoryStatus: res?.factoryInformation?.status?.status,
          company: res?.factoryInformation?.factoryOwners
            ?.map((item: IdNamePair) => item.name)
            ?.join(", "),
          country: countries?.find(
            (item: Country) => item.id === res?.factoryInformation?.countryId,
          )?.name,
        },
        details: {
          functions: res?.factoryInformation?.factoryFunctions
            ?.map((item: GenericType) => item.function)
            ?.join(", "),
          equipmentType: res?.factoryInformation?.equipmentTypes
            ?.map((eq: GenericType) => eq.type)
            ?.join(", "),
          productionCapacity:
            res?.factoryInformation?.factoryDetails?.productionCapacity,
          totalArea: res?.factoryInformation?.factoryDetails?.totalAreaSqm,
          productionFloorArea:
            res?.factoryInformation?.factoryDetails?.productionFloorSqm,
          coveredProductionArea:
            res?.factoryInformation?.factoryDetails?.coveredFabricationAreaSqm,
          storageArea: res?.factoryInformation?.factoryDetails?.storageAreaSqm,
          quayLength: res?.factoryInformation?.factoryDetails?.quayLengthM,
          quayDraught: res?.factoryInformation?.factoryDetails?.quayDraughtM,
          closureDate: res?.factoryInformation?.closureDate,
          operationDate: res?.factoryInformation?.operationDate,
          productDetails:
            res?.factoryInformation?.factoryDetails?.productDetails,
          factoryUpdates: res?.factoryInformation?.factoryDetails?.comment,
        },
        turbinesContract: res?.turbineContracts?.map((item: GenericType) => ({
          title: `Turbine - ${item?.contract?.windProject?.name}`,
          awardDate: item?.contract?.contract?.awardDate,
          //check
          supplier: item?.contract?.contract?.contractedCompanies
            ?.map((c: GenericType) => c?.contractee?.name)
            ?.join(", "),
          windFarm: item?.contract?.windProject?.name,
          country: countries?.find(
            (c: Country) => c.id === item?.contract?.windProject?.countryId,
          )?.name,
          numberOfTurbines: item?.numOrdered,
          turbineMode: item?.turbineModel?.name,
          contractStatus: item?.contract?.contract?.contractStatus?.status,
        })),
        fixedFoundationContract: res?.foundationContracts?.map(
          (item: GenericType) => ({
            title: `Fixed Foundation - ${item?.contract?.windProject?.name}`,
            awardDate: item?.contract?.contract?.awardDate,
            supplier: item?.contract?.contract?.contractedCompanies
              ?.map((c: GenericType) => c?.contractee?.name)
              ?.join(", "),
            windFarm: item?.contract?.windProject?.name,
            country: countries?.find(
              (c: Country) => c.id === item?.contract?.windProject?.countryId,
            )?.name,
            numberOfFoundations: item?.numOrdered,
            //check
            foundationType: item?.fixedFoundationType?.type,
            contractStatus: item?.contract?.contract?.contractStatus?.status,
            factory: res?.factoryInformation?.name,
            foundationMass: item?.fixedFoundationMetrics?.maxFoundationMassMt,
            foundationLength: item?.fixedFoundationMetrics?.maxFoundationDepthM,
            foundationDiameter:
              item?.fixedFoundationMetrics?.maxFoundationDiameterM,
            foundationThickness:
              item?.fixedFoundationMetrics?.maxFoundationThicknessM,
          }),
        ),
        cablesContract: res?.cableContracts?.map((item: GenericType) => ({
          title: `Cable - ${item?.contract?.windProject?.name}`,
          awardDate: item?.contract?.contract?.awardDate,
          supplier: item?.contract?.contract?.contractedCompanies
            ?.map((c: GenericType) => c?.contractee?.name)
            ?.join(", "),
          windFarm: item?.contract?.windProject?.name,
          country: countries?.find(
            (c: Country) => c.id === item?.contract?.windProject?.countryId,
          )?.name,
          cableType: item?.cable?.cableType?.type,
          contractStatus: item?.contract?.contract?.contractStatus?.status,
          factory: res?.factoryInformation?.name,
          voltage: item?.voltage?.arrayCableVoltage,
          outputCurrent: item?.outputCurrent?.type,
          numberOfCables: item?.numOrdered,
          cableLength: item?.cable?.cableLengthKm,
          dynamicCable: item?.cable?.dynamic,
        })),
        substationsContract: res?.substationContracts?.map(
          (item: GenericType) => ({
            title: `Substations - ${item?.contract?.windProject?.name}`,
            awardDate: item?.contract?.contract?.awardDate,
            supplier: item?.contract?.contract?.contractedCompanies
              ?.map((c: GenericType) => c?.contractee?.name)
              ?.join(", "),
            windFarm: item?.contract?.windProject?.name,
            country: countries?.find(
              (c: Country) => c.id === item?.contract?.windProject?.countryId,
            )?.name,
            substation: item?.substation?.name,
            components: item?.contract?.contract?.equipmentTypes
              ?.map((eq: GenericType) => eq.type)
              .join(", "),
            contractStatus: item?.contract?.contract?.contractStatus?.status,
            factory: res?.factoryInformation?.name,
            topsidesMass: item?.substation?.topsideMassMt,
            topsidesHeight: item?.substation?.topsideHeightM,
            topsidesWidth: item?.substation?.topsideWidthM,
            topsidesLength: item?.substation?.topsideLengthM,
            foundationType: item?.substation?.fixedFoundationType?.type,
            foundationMass: item?.substation?.foundationMassMt,
            foundationHeight: item?.substation?.foundationHeightM,
            foundationWidth: item?.substation?.foundationWidthM,
            foundationLength: item?.substation?.foundationLegsNum,
            totalMass: item?.substation?.totalMassMt,
            numberJacketLegs: item?.numOrdered,
            outputCurrent: item?.outputCurrent?.type,
            voltageIn: item?.voltage?.arrayCableVoltage,
            voltageOut: item?.voltage?.exportCableVoltage,
            factories: res?.factoryInformation?.name,
          }),
        ),
        towersContract: res?.towerContracts?.map((item: GenericType) => ({
          title: `Tower - ${item?.contract?.windProject?.name}`,
          awardDate: item?.contract?.contract?.awardDate,
          supplier: item?.contract?.contract?.contractedCompanies
            ?.map((c: GenericType) => c?.contractee?.name)
            ?.join(", "),
          windFarm: item?.contract?.windProject?.name,
          country: countries?.find(
            (c: Country) => c.id === item?.contract?.windProject?.countryId,
          )?.name,
          contractStatus: item?.contract?.contract?.contractStatus?.status,
          factory: res?.factoryInformation?.name,
          numberOfTowers: item?.numOrdered,
          numberOfSections: item?.towerMetrics?.totalNumSections,
          sectionHeight: item?.towerMetrics?.sectionHeightM,
          towerHeight: item?.towerMetrics?.towerHeightM,
          towerMass: item?.towerMetrics?.towerMassMt,
        })),
        floatingEquipmentContract: res?.floatingContracts?.map(
          (item: GenericType) => ({
            title: `Floating Equipment - ${item?.contract?.windProject?.name}`,
            awardDate: item?.contract?.contract?.awardDate,
            supplier: item?.contract?.contract?.contractedCompanies
              ?.map((c: GenericType) => c?.contractee?.name)
              ?.join(", "),
            windFarm: item?.contract?.windProject?.name,
            country: countries?.find(
              (c: Country) => c.id === item?.contract?.windProject?.countryId,
            )?.name,
            components: item?.contract?.contract?.equipmentTypes
              ?.map((eq: GenericType) => eq.type)
              .join(", "),
            numberOfUnits: item?.numOrdered,
            factories: res?.factoryInformation?.name,
            floatingTechnologyType: item?.floatingTechnology?.technology,
          }),
        ),
      };
      setData(result);
    } catch (err) {
      console.log(">>>", err);
      onClose();
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchFactoryData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data ? data?.keyInfo?.factoryName : "Factory"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              factoryName: { key: "Factory name" },
              company: { key: "Company" },
              country: { key: "Country" },
              factoryStatus: {
                key: "Factory status",
              },
            }}
            data={data.keyInfo}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Factory details"
            properties={{
              functions: { key: "Functions" },
              equipmentType: { key: "Equipment type" },
              productionCapacity: { key: "Production capacity", unit: "unit" },
              totalArea: {
                key: "Total area",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              productionFloorArea: {
                key: "Production floor area",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              coveredProductionArea: {
                key: "Covered production area",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              storageArea: {
                key: "Storage area",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              quayLength: {
                key: "Quay length",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              quayDraught: {
                key: "Quay draught",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              productDetails: {
                key: "Product details",
              },
              factoryUpdates: {
                key: "Factory updates",
              },
              // operationDate: {
              //   key: "Operation Date",
              //   type: "date",
              // },
              // closureDate: {
              //   key: "Closure Date",
              //   type: "date",
              // },
            }}
            data={data?.details}
          />
          {data?.turbineContracts?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Turbine Contract"
                subTitleKey="title"
                name="contract"
                data={data.turbineContracts}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  numberOfTurbines: { key: "Number of turbines" },
                  turbineMode: { key: "Turbine model" },
                  contractStatus: { key: "Contract status" },
                }}
              />
            </>
          ) : null}
          {data?.fixedFoundationContract?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Fixed Foundation Contract"
                subTitleKey="title"
                name="contract"
                data={data.fixedFoundationContract}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  numberOfFoundations: { key: "Number of foundations" },
                  foundationType: { key: "Foundation type" },
                  contractStatus: { key: "Contract status" },
                  factory: { key: "Factory" },
                  foundationMass: { key: "Foundation mass", unit: "t" },
                  foundationLength: { key: "Foundation length", unit: "m" },
                  foundationDiameter: { key: "Foundation diameter", unit: "m" },
                  foundationThickness: {
                    key: "Foundation thickness",
                    unit: "m",
                  },
                }}
              />
            </>
          ) : null}
          {data?.cablesContract?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Cable Contract"
                subTitleKey="title"
                name="contract"
                data={data.cablesContract}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  cableType: { key: "Cable type" },
                  contractStatus: { key: "Contract status" },
                  factory: { key: "Factory" },
                  voltage: { key: "Voltage", unit: "kV" },
                  outputCurrent: { key: "Output current" },
                  numberOfCables: { key: "Number of cables" },
                  cableLength: { key: "Cable length", unit: "km" },
                  dynamicCable: { key: "Dynamic cable" },
                }}
              />
            </>
          ) : null}
          {data?.substationsContract?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Substation Contract"
                subTitleKey="title"
                name="contract"
                data={data.substationsContract}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  substation: { key: "Substation" },
                  components: { key: "Components" },
                  contractStatus: { key: "Contract status" },
                  factory: { key: "Factory" },
                  topsidesMass: { key: "Topsides mass", unit: "mt" },
                  topsidesHeight: { key: "Topsides height", unit: "m" },
                  topsidesWidth: { key: "Topsides width", unit: "m" },
                  topsidesLength: { key: "Topsides length", unit: "m" },
                  foundationType: { key: "Foundation type" },
                  foundationMass: { key: "Foundation mass", unit: "mt" },
                  foundationHeight: { key: "Foundation height", unit: "m" },
                  foundationWidth: { key: "Foundation width", unit: "m" },
                  foundationLength: { key: "Foundation length", unit: "m" },
                  totalMass: { key: "Total mass", unit: "mt" },
                  numberJacketLegs: { key: "Number of jacket legs" },
                  outputCurrent: { key: "Output current" },
                  voltageIn: { key: "Voltage in", unit: "kV" },
                  voltageOut: { key: "Voltage out", unit: "kV" },
                  factories: { key: "Factories" },
                }}
              />
            </>
          ) : null}
          {data?.towersContract?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Tower Contract"
                subTitleKey="title"
                name="contract"
                data={data.towersContract}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  contractStatus: { key: "Contract status" },
                  factory: { key: "Factory" },
                  numberOfTowers: { key: "Number of towers" },
                  numberOfSections: { key: "Number of sections" },
                  sectionHeight: { key: "Section height" },
                  towerHeight: { key: "Tower height" },
                  towerMass: { key: "Tower mass" },
                }}
              />
            </>
          ) : null}
          {data?.floatingEquipmentContract?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Floating Equipment Contract"
                subTitleKey="title"
                name="contract"
                data={data.floatingEquipmentContract}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Contract award date", type: "date" },
                  supplier: { key: "Supplier" },
                  windFarm: { key: "Wind farm" },
                  country: { key: "Country" },
                  components: { key: "Components" },
                  numberOfUnits: { key: "Number of units" },
                  factories: { key: "Factories" },
                  floatingTechnologyType: { key: "Floating technology type" },
                }}
              />
            </>
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
