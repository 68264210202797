import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Radio, RadioGroup } from "@esgian/esgianui";

import { ProjectDrawer } from "../../components";
import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { PowerOutputProjectList } from "../../components/PowerOutputProjectList";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getIsLookupLoading,
  getSelectedProjectId,
} from "../../store/selector/common";
import {
  getIsLoading,
  getOptions,
  getSelectedFilter,
  getTab,
} from "../../store/selector/powerOutput";
import { setFilterModal, setSelectedProjectId } from "../../store/slice/common";
import {
  fetchList,
  setSelectedFilter,
  setTab,
} from "../../store/slice/powerOutput";
import { PowerOutputEnum, ROUTES_CONFIG } from "../../types";

export const PowerOutputList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLookupLoading = useSelector(getIsLookupLoading);
  const selectedProjectId = useSelector(getSelectedProjectId);
  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getOptions);
  const selectedFilter = useSelector(getSelectedFilter);
  const isLoading = useSelector(getIsLoading);
  const tab = useSelector(getTab);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTab(event?.target?.value as PowerOutputEnum));
  };

  useEffect(() => {
    if (!isLookupLoading) {
      dispatch(fetchList());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupLoading]);

  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  return (
    <Box>
      <Box sx={{ mx: 2 }}>
        <RadioGroup
          row={true}
          aria-labelledby="view-radio-buttons"
          name="view-radio-buttons"
          value={tab}
          onChange={handleChange}
        >
          <FormControlLabel
            value={PowerOutputEnum.WindFarm}
            control={<Radio />}
            label="Wind Farm"
          />
          <FormControlLabel
            value={PowerOutputEnum.Developer}
            control={<Radio />}
            label="Developer"
          />
          <FormControlLabel
            value={PowerOutputEnum.TurbineSupplier}
            control={<Radio />}
            label="Turbine"
          />
          <FormControlLabel
            value={PowerOutputEnum.Country}
            control={<Radio />}
            label="Country"
          />
          <FormControlLabel
            value={PowerOutputEnum.ControlArea}
            control={<Radio />}
            label="Control Area"
          />
        </RadioGroup>
      </Box>
      {!isLookupLoading && !isLoading ? (
        <PowerOutputProjectList tab={tab} />
      ) : (
        <Loading />
      )}
      <ProjectDrawer
        id={selectedProjectId}
        onClose={() => dispatch(setSelectedProjectId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
          options={options}
          selectedFilter={selectedFilter}
        />
      ) : null}
    </Box>
  );
};
